import React from 'react';

import iconWhite from '../assets/images/icon-white.png';
import iconBlack from '../assets/images/icon.png';
import IconBlack from '!svg-react-loader!../assets/icons/icon-black.svg?name=IconBlack';
import IconWhite from '!svg-react-loader!../assets/icons/icon-white.svg?name=IconWhite';

interface Props {
  size?: number;
  variant: 'black' | 'white';
}

const Icon: React.FC<Props> = ({ size = 512, variant }) => (
  variant === 'white' ? <IconWhite width={size} /> : <IconBlack width={size} />
);

export default Icon;
