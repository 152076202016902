import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import 'semantic-ui-css/semantic.min.css'

import '../assets/sass/main.scss';
import Footer from './Footer';
import SideBar from './Header';

interface Props {
  className: string;
  children: React.ReactNode;
}

interface State {
  isPreloaded: boolean;
}

class Layout extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isPreloaded: true,
    };
  }

  static defaultProps: Props = {
    className: '',
    children: null,
  };

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ isPreloaded: false });
    }, 100);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    const { children, fullMenu } = this.props;
    const { isPreloaded } = this.state;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: 'Expectation Church exists to bring Jesus to every heart & home in Dublin & beyond. Join us this Sunday @ 10.30am at VUE Cinema, Liffey Valley, Dublin.' },
                { name: 'keywords', content: 'site, web' },
              ]}
            >
              <html lang="en" />
              <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
              <link rel="manifest" href="/site.webmanifest" />
              <script data-goatcounter="https://weareexpectation.goatcounter.com/count" async src="//gc.zgo.at/count.js"></script>
            </Helmet>
            <div
              // className={isPreloaded ? `is-preload ${this.props.className}` : this.props.className}
              className={
                this.props.className
                  ? (isPreloaded
                    ? 'is-preload'
                    : '')
                  : (isPreloaded
                    ? 'landing main-body is-preload'
                    : 'landing main-body')
              }
            >
              <div id="page-wrapper" className={this.props.className}>
                <SideBar fullMenu={fullMenu} />
                {children}
                <Footer />
              </div>
            </div>
          </>
        )}
      />
    );
  }
}

export default Layout;
