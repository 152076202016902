import React from 'react';
import { FaFacebook } from '@react-icons/all-files/fa/FaFacebook';
import { FaEnvelope } from '@react-icons/all-files/fa/FaEnvelope';
import { FaYoutube } from '@react-icons/all-files/fa/FaYoutube';
import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Icon from '../components/Icon';

import config from '../../config';

const Footer = ({ className }) => {
  return (
    <footer id="footer" className={className}>
      <Icon variant="white" size={100} />
      <div className="icons">
        {config.socialLinks.map(social => {
          const { icon, name, url } = social;
          return (
            <a key={name} href={url} target="_blank">
              {icon === "facebook" && <FaFacebook />}
              {/* {icon === "youtube" && <FaYoutube />} update the grid template */}
              {icon === "instagram" && <FaInstagram />}
              {icon === "envelope" && <FaEnvelope />}
            </a>
          );
        })}
      </div>
    </footer>
  );
}

export default styled(Footer)`
  background-color: black;
  z-index: 1;

  display: grid;
  grid-gap: 30px;
  padding: 40px 0;
  justify-content: center;
  height: 190px;

  > * {
    align-self: center;
    justify-self: center;
  }

  > .icons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }

  > .navigation {
    display: flex;
    flex-direction: column;
  }
`;
