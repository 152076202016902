import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Nav from './Nav';
import Expectation from '!svg-react-loader!../assets/icons/expectation.svg?name=Expectation';
import { useScrollPosition } from '../hooks/useScrollPosition';
import Icon from './Icon';

const Header = ({ className }) => {
  const [headerOpen, toggleHeader] = useState(false);
  const [sticky, setSticky] = useState(false);

  useScrollPosition(({ currPos }) => {
    const isShow = currPos.y < -100;
    if (isShow !== sticky) setSticky(isShow);
  }, [sticky]);

  return (
    <header
      style={{ transform: sticky ? 'translateY(-100%)' : 'translateY(0)' }}
      className={className}
      id="header"
    >
      <Link className="home-link" to="/">
        <Icon variant="white" size={60} />
        <Expectation width="150" />
      </Link>
      <nav className="desktop">
        <div className="dropdown">
          <a>ABOUT</a>
          <div className="desktop-dropdown-content">
            <Link to="/our-story">OUR STORY</Link>
            <Link to="/whats-on">WHAT'S ON</Link>
            <Link to="/our-vision">OUR VISION</Link>
            <Link to="/our-values">OUR VALUES</Link>
            <Link to="/our-beliefs">OUR BELIEFS</Link>
          </div>
        </div>
        <div className="dropdown">
          <a>CONNECT</a>
          <div className="desktop-dropdown-content">
            <Link to="/connect">FIND OUT MORE</Link>
            <Link to="/join-team">JOIN A TEAM</Link>
          </div>
        </div>
        <Link to="/giving">GIVE</Link>
        {/* <Link to="/Generic">Generic Page</Link>
        <Link to="/Elements">Elements</Link> */}
      </nav>
      <div className={`${headerOpen ? 'is-menu-visible mobile' : 'mobile'}`}>
        <Nav onMenuToggle={() => toggleHeader(!headerOpen)} />
      </div>
    </header>
  );
}

export default styled(Header)`
  font-family: 'Horizon';
  & .home-link {
    display: flex;
    svg {
      fill: white;
    }
  }

  & .dropdown {
    position: relative;
    display: inline-block;

    .desktop-dropdown-content {
      display: none;
      position: absolute;
      background-color: black;
      min-width: 230px;
      box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.2);
      padding: 12px 16px;
      z-index: 1;

      a {
        display: block;
        font-size: 0.8rem;
      }
    }

    &:hover .desktop-dropdown-content,
    &:focus .desktop-dropdown-content {
      display: block;
    }
  }
`;
