import React, { useState } from 'react';
import { Link } from 'gatsby';
import { FaBars } from '@react-icons/all-files/fa/FaBars';
import { FaTimes } from '@react-icons/all-files/fa/FaTimes';
import styled from 'styled-components';

const Nav = ({ className = '', onMenuToggle = () => null }) => {
  const [connectDropdownOpened, setConnectDropdownOpened] = useState(false);
  const [aboutDropdownOpened, setAboutDropdownOpened] = useState(false);

  return (
    <nav className={className} id="nav">
      <ul>
        <li className="special">
          <FaBars
            className="icon"
            color="white"
            onClick={e => {
              e.preventDefault();
              onMenuToggle();
            }}
          />
          <div id="menu">
            <ul>
              <li className={`dropdown ${aboutDropdownOpened ? 'open' : ''}`} onClick={(e) => setAboutDropdownOpened((current) => !current)}>
                <a>ABOUT</a>
                <div className="mobile-dropdown-content">
                  <Link to="/our-story">OUR STORY</Link>
                  <Link to="/whats-on">WHAT'S ON</Link>
                  <Link to="/our-vision">OUR VISION</Link>
                  <Link to="/our-values">OUR VALUES</Link>
                  <Link to="/our-beliefs">OUR BELIEFS</Link>
                </div>
              </li>
              <li className={`dropdown ${connectDropdownOpened ? 'open' : ''}`} onClick={(e) => setConnectDropdownOpened((current) => !current)}>
                <a>CONNECT</a>
                <div className="mobile-dropdown-content">
                  <Link to="/connect">FIND OUT MORE</Link>
                  <Link to="/join-team">JOIN A TEAM</Link>
                </div>
              </li>
              <li>
                <Link to="/giving">GIVE</Link>
              </li>
            </ul>
            <FaTimes
              className="close"
              color="white"
              onClick={e => {
                e.preventDefault();
                onMenuToggle();
              }}
            />
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default styled(Nav)`
  & .close {
    cursor: pointer;
    display: block;
    position: absolute;
    margin: 10px;
    right: 0;
    top: 0;
  }

  & .dropdown {
    position: relative;
    display: block !important;

    .mobile-dropdown-content {
      display: none;
      margin-left: 10px;

      a {
        display: block;
        font-size: 0.6rem;
      }
    }

    &.open .mobile-dropdown-content,
    &.open .mobile-dropdown-content {
      display: block;
    }
  }

  /* iPads (portrait) ----------- */
  @media only screen and (max-width : 768px) {
    nav {
      height: inherit;
      line-height: inherit;
      position: absolute;
      right: 0;
      top: 0;

      > ul {
        list-style: none;
        margin: 0;
        padding: 0;
        white-space: nowrap;

        > li {
          display: block;
          padding: 0;

          > a {
            border: 0;
            color: _palette(fg-bold);
            display: block;
            font-size: 0.8em;
            letter-spacing: _size(letter-spacing-alt);
            padding: 0 1.5em;
            text-transform: uppercase;

            &.menuToggle {
              outline: 0;
              position: relative;

              &:after {
                background-image: url('../images/bars.png');
                background-position: right center;
                background-repeat: no-repeat;
                content: '';
                display: inline-block;
                height: 3.75em;
                vertical-align: top;
                width: 2em;
              }

              @include breakpoint('<=small') {
                padding: 0 1.5em;
              }
            }

            @include breakpoint('<=small') {
              padding: 0 0 0 1.5em;
            }
          }

          &:first-child {
            margin-left: 0;
          }
          &:last-of-type {
            border-bottom: solid 1px rgba(0, 0, 0, 0.125);
          }
        }
      }
    }
  }
`;
